<template>
    <v-navigation-drawer
        id="appDrawer"
        fixed
        app
        width="260"
        v-model="drawer"
        mobile-break-point="992"
    >
        <v-toolbar color="primary darken-1" dark>
            <img src="/static/m.png" height="36" alt="logo sgc web">
            <v-toolbar-title class="ml-0 pl-3">
                <span class="hidden-sm-and-down">SGC WEB</span>
            </v-toolbar-title>        
        </v-toolbar>
        <v-list dense expand>
            <template v-for="(item, i) in getMenus">
                <!--group with subitems-->
                <v-list-group v-if="mostrarmenues(item) && item.items" :key="item.name" :group="item.group" :prepend-icon="item.icon" no-action="no-action">
                <v-list-tile slot="activator" ripple="ripple">
                    <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <template v-for="(subItem, i) in item.items">
                    <!--sub group-->
                    <v-list-group v-if="subItem.items" :key="subItem.name" :group="subItem.group" sub-group="sub-group">
                        <v-list-tile slot="activator" ripple="ripple">
                            <v-list-tile-content>
                            <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-for="(grand, i) in subItem.children" :key="i" :to="genChildTarget(grand)" ripple="ripple">
                            <v-list-tile-content>
                            <v-list-tile-title>{{ grand.title }}</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list-group>
                    <!--child item-->
                    <template v-else-if="mostrarmenues(subItem)">
                        <v-list-tile :key="i" :to="genChildTarget(subItem)" ripple="ripple">
                            <v-list-tile-content>
                                <v-list-tile-title><span>{{ subItem.title }}</span></v-list-tile-title>
                            </v-list-tile-content>
                            <v-list-tile-action v-if="subItem.action">
                                <v-icon :class="[subItem.actionClass || 'success--text']">{{ subItem.action }}</v-icon>
                            </v-list-tile-action>
                        </v-list-tile>
                    </template>
                </template>
                </v-list-group>
                <v-subheader v-else-if="item.header" :key="i">{{ item.header }}</v-subheader>
                <v-divider v-else-if="item.divider" :key="i"></v-divider>
                <!--top-level link-->
                <template v-else-if="mostrarmenues(item)">
                    <v-list-tile ripple="ripple" rel="noopener" :key="item.name" :to="{name: item.name}">
                        <v-list-tile-action v-if="item.icon" >
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>{{ item.title}}</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </template>
            </template>
        </v-list>  
        <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>
    </v-navigation-drawer>
</template>
<script>
import alert from '@/components/alert/alert.vue'
import MenuServices from '@/services/MenuServices'
import PermitServices from '@/services/PermitServices'
export default {
    name: 'Aside',
    components: {alert},
    data() {
        return {
            notificacion: {mensaje: '',tipo: 'error'},
            menus: MenuServices,
            drawer: true,
            permisos: {}
        }
    },
    computed: {
        getMenus() {
            return this.menus;
        }
    },
    beforeCreate() {
        PermitServices.all()
        .then(res => {
            this.permisos = res;
        })
        .catch(err => {
            this.notificacion.tipo = "error";
            this.notificacion.mensaje =
                "Ocurrio un error al intentar recuperar los permisos.";
            if (
                err.response &&
                err.response.data.hasOwnProperty("errorMessage")
            ) {
                this.notificacion.mensaje = err.response.data.errorMessage;
            }
        });
    },
    created() {
        this.$bus.$on('toggleNavigationDrawer', () =>{
            this.drawer = !this.drawer
        });
        this.$bus.$on('PedidoNuevoContinuar', (label) => {
            for (let index = 0; index < this.menus.length; index++) {
                if(this.menus[index].group == 'Pedidos') {
                    const valorActual = this.menus[index].items[1].title = label;
                    if(label != valorActual) {
                        this.menus[index].items[1].title = label;
                    }
                    return;
                }
            }
        });
    },
    methods: {
        genChildTarget(subItem) {
            return { name: `${(subItem.name)}` };
        },
        mostrarmenues(item){
            // console.log(item)
            // return true
            //El item inicio lo cargamos siempre
            if(item.modulo === 0 && item.funcion === 0){return true}

            for (let index = 0; index < this.permisos.length; index++) {
                if(item.modulo === this.permisos[index].webMod_codigo){
                    if(item.funcion == this.permisos[index].webFun_codigo){
                        return true
                    }
                    if(item.funcion === 0){
                        return true
                    }
                }
            }
            return false
        }
    }
}
</script>
