import api from './api'

const userService = {}

userService.login = async function(username, password) {
    return api.post('/Token/Login', {}, {
        auth: {
            username,
            password
        }
    }).then(res => res.data)
}

userService.logout = function() {
    localStorage.clear()
}

userService.current = function() {
    return JSON.parse(localStorage.user);
}

userService.valirdarToken = function() {
    let user = userService.current();
    user.exp = user.exp + '000';
    var fecha = Date.parse(Date());
    if (fecha > user.exp) {
        return false;
    }
    return true;
}

userService.autenticado = function() {
    if (localStorage.user && userService.valirdarToken()) {
        return true
    }
    userService.logout();
    return false
}

userService.IsAdmin = function() {
    let Cliente = JSON.parse(userService.current().Cliente);
    if (Cliente !== null && typeof Cliente === 'object') {
        return false;
    }
    return true;
}

userService.CambiarPass = async function(passAnterior, passNueva, passRepetir) {
    return api.post('/Usuario/CambiarPass', { passAnterior, passNueva, passRepetir }).then(res => res.data);
}

export default userService